import React from "react";
import '../Style/aboutMe.css';
import { OfflineWeb } from "../../Maintenance/offline";
import { AboutHeaderComponents } from "../Components/header";
import tdroid from '../Assets/TdroidChart.png';
import css from '../Assets/Icons/CSS.png';
import blender from '../Assets/Icons/Blender.png';
import bootstrap from '../Assets/Icons/Bootstrap_logo.svg.png';
import docker from '../Assets/Icons/Docker.png';
import html from '../Assets/Icons/HTML.png';
import express from '../Assets/Icons/icon-express.png';
import git from '../Assets/Icons/icon-git.png';
import javscript from '../Assets/Icons/icon-javscript.png';
import nodejs from '../Assets/Icons/icon-nodejs.png';
import react from '../Assets/Icons/icon-react.png';
import typescript from '../Assets/Icons/icon-typescript.png';
import postgresql from '../Assets/Icons/PostgreSQL.png';
import swift from '../Assets/Icons/Swift.png';
import tech from '../Assets/Icons/Tech.png';
import { FooterComponent } from "../Components/Footer/footer";
import { DownloadButton } from "../Components/download/button";


const AboutMePage = () => {

    let hasAuthorization = true;
    if (!hasAuthorization) {
        return (
            <div>
                <OfflineWeb />
            </div>
        )
    }

    return (
        <div className="Body">
            <AboutHeaderComponents />
            <div className="about_sections">
                <div className="about_section1">
                    <h1 className="title_about about_texts">Olá! Tdroid aqui 👋</h1>
                    <p className="subtitle_about about_texts">Sou um desenvolvedor FullStack(back-end e front-end). Sou formado em desenvolvimento Web, iOS e crio alguns bots no Discord. Também uma produtora que promove redes sociais criando comerciais/anúncios e deixando mais profissional, deixando-as pronta para o mercado digital</p>
                    <div className="about_Section_Network">
                        <div className="about_subSection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            <p className="about_localeDescription">Rio De Janeiro, Brazil</p>
                        </div>

                        <div className="about_subSection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="8" className="green" />
                            </svg>

                            <p className="about_projectContract about_localeDescription">Disponível para projetos</p>
                        </div>

                        {/* Social Network */}
                        <div className="about_sectionSocial">
                            <a href="https://github.com/Tdroid20" target="blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github about_Social" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                            </svg></a>

                            <a href="https://youtube.com/@tdroid" target="blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube about_Social" viewBox="0 0 16 16">
                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                            </svg></a>

                            <a href="https://www.instagram.com/tdroid2.0/" target="blank"><svg id="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram about_Social" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                            </svg></a>

                            <a href="https://www.linkedin.com/in/tdroid/" target="blank"><svg id="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin about_Social" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg></a>


                            <a href="https://www.twitch.tv/tdroid20" target="blank"><svg id="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitch about_Social" viewBox="0 0 16 16">
                                <path d="M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0H3.857zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142v6.286z" />
                                <path d="M11.857 3.143h-1.143V6.57h1.143V3.143zm-3.143 0H7.571V6.57h1.143V3.143z" />
                            </svg></a>
                        </div>
                    </div>
                </div>
                <div className="about_section2">
                    {/* Image */}
                    <img src={tdroid} alt="Personagem Tdroid" className="about_avatar" />
                </div>
            </div>

            <div className="container_Skills">
                <div className="about_skillsCard">
                    <div className="about_skillsInternal">
                        <p className="aboutSkills_title">Skils</p>
                    </div>
                </div>

                <div>
                    <p className="about_descriptionSkill">As habilidades, ferramentas e tecnologias nas quais sou bom:</p>
                </div>

                <section className="about_container">
                    <div className="about_skillsImage">
                        <img src={css} alt="CSS logo" className="skillImg" id="CSS" />
                        <p className="about_description">CSS</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={blender} alt="Blender logo" className="skillImg" id="Blender" />
                        <p className="about_description">Blender</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={bootstrap} alt="Bootstrap logo" className="skillImg" id="Bootstrap" />
                        <p className="about_description">Bootstrap</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={docker} alt="Docker logo" className="skillImg" id="Docker" />
                        <p className="about_description">Docker</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={html} alt="html 5 logo" className="skillImg" id="HTML" />
                        <p className="about_description">HTML</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={express} alt="Express logo" className="skillImg" id="Express" />
                        <p className="about_description">Express</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={git} alt="Git logo" className="skillImg" id="Git" />
                        <p className="about_description">Git</p>
                    </div>

                </section>

                <section className="about_container">
                    <div className="about_skillsImage">
                        <img src={javscript} alt="JavaScript logo" className="skillImg" id="JavaScript" />
                        <p className="about_description" id="">JavaScript</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={nodejs} alt="NodeJS logo" className="skillImg" id="NodeJS" />
                        <p className="about_description">NodeJS</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={react} alt="React logo" className="skillImg" id="React" />
                        <p className="about_description">React</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={typescript} alt="Typescript logo" className="skillImg" id="Typescript" />
                        <p className="about_description">TypeScript</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={postgresql} alt="PostgresSQL logo" className="skillImg" id="PostgresSQL" />
                        <p className="about_description">PostgresSQL</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={swift} alt="Swift logo" className="skillImg" id="Swift" />
                        <p className="about_description">Swift</p>
                    </div>
                    <div className="about_skillsImage">
                        <img src={tech} alt="MongoDB logo" className="skillImg" id="MongoDB" />
                        <p className="about_description">MongoDB</p>
                    </div>


                </section>
            </div>
            <FooterComponent />
        </div>
    )
};

export default AboutMePage;